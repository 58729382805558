import Cookie from "js-cookie"
import { siteMetadata } from "../gatsby-config"
import { insertScript } from "./tracking/trackingHelper";
import { getCookieFirst } from "./tracking/cookieFirst";

export async function setGaOptOut() {
    const cf = await getCookieFirst()

    cf.withdrawConsent()
}


const gaCode = siteMetadata.analytics

const gaDisable = "ga-disable-" + gaCode

const gaScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${gaCode}', {
        anonymize_ip: true
    });
`

export function addAnalytics() {
    // google opt out
    if (Cookie.get(gaDisable) === "true") {
        // @ts-ignore
        window[gaDisable] = true
        return
    }

    // respect dnt
    if (
        navigator.doNotTrack === "1"
        || parseInt(window.doNotTrack) === 1
        || navigator.doNotTrack === "yes"
        // @ts-ignore
        || navigator.msDoNotTrack === "1"
    ) {
        return
    }

    if (document.getElementById("ga-script")) {
        return
    }

    const script = document.createElement("script")
    script.id = "ga-script"
    script.innerHTML = gaScript

    document.body.append(script)

    insertScript(`https://www.googletagmanager.com/gtag/js?id=${gaCode}`)
}

export const ga_event = (eventName: Gtag.EventNames | string, eventParams?: Gtag.EventParams | Gtag.CustomParams) => {
    if (!window.gtag) {
        return
    }

    window.gtag("event", eventName, eventParams)
}
