require("dotenv").config({
  path: `.env`,
})

const generateFavicons = (sizes) => {
    return sizes.map(size => {
        return {
            src: `favicons/icon-${size}x${size}.png`,
            sizes: `${size}x${size}`,
            type: "image/png",
        };
    });
};

module.exports = {
    siteMetadata: {
        title: "Quadio",
        description: "Ihre digitalen Lösungen für smartes Wissensmanagement, starke Unternehmenskultur, effektive Onboardings und zielgerichtetes Branding.",
        author: "Quadio GmbH",
        siteUrl: "https://quadio.de",
        languages: {
            langs: ["de", "en"],
            defaultLangKey: "de",
        },
        analytics: "G-8DXF6HTZPZ",
    },
    plugins: [
        "gatsby-plugin-typescript",
        {
            resolve: "gatsby-plugin-eslint",
            options: {
                test: /\.[jt]s(x)?$/,
                exclude: /(node_modules|.cache|public)/,
                stages: ["develop"],
                options: {
                    emitWarning: true,
                    failOnError: false,
                },
            },
        },
        "gatsby-plugin-react-helmet-async",
        {
            resolve: "gatsby-source-filesystem",
            options: {
                name: "images",
                path: `${__dirname}/src/images`,
            },
        },
        "gatsby-transformer-sharp",
        "gatsby-plugin-sharp",
        {
            resolve: "gatsby-plugin-manifest",
            options: {
                name: "quadio-website",
                short_name: "starter",
                start_url: "/",
                background_color: "#efefef",
                theme_color: "#efefef",
                display: "browser",
                // Icons Hybrid mode
                // In automatic mode, icons will be exported to /icons
                // This conflicts with the Apache /icons symlink to /usr/share/apache2/icons/
                // (https://www.electrictoolbox.com/apache-icons-directory)
                // The solution is to create a favicons folder instead of the default icons folder
                icon: "src/images/icons/logo.svg",
                icons: generateFavicons([48, 72, 96, 144, 192, 256, 384, 512]),
            },
        },
        {
            resolve: "gatsby-source-filesystem",
            options: {
                path: `${__dirname}/src/pages/blog`,
                name: "blog",
            },
        },
        "gatsby-transformer-remark",
        {
            resolve: "gatsby-plugin-i18n",
            options: {
                langKeyDefault: "de",
                useLangKeyLayout: false,
                prefixDefault: false,
                // markdownRemark: {
                //     postPage: 'src/templates/BlogPost.tsx',
                //     query: `
                //         {
                //             allMarkdownRemark {
                //                 edges {
                //                     node {
                //                         fields {
                //                             slug
                //                             langKey
                //                         }
                //                     }
                //                 }
                //             }
                //         }
                //     `
                // }
            }
        },
        // this (optional) plugin enables Progressive Web App + Offline functionality
        // To learn more, visit: https://gatsby.dev/offline
        // "gatsby-plugin-offline",
        "gatsby-plugin-postcss",
        "gatsby-transformer-sharp",
        {
            resolve: "gatsby-plugin-sharp",
            options: {
                defaultQuality: 90,
            }
        },
        {
            resolve: "gatsby-source-filesystem",
            options: {
                name: "images",
                path: `${__dirname}/src/images/`,
            },
        },
        {
            resolve: "gatsby-plugin-svgr-loader",
            options: {
                rule: {
                    include: /\.inline\.svg$/
                }
            }
        },
        "gatsby-plugin-optimize-svgs",
        "gatsby-plugin-sitemap",
        {
            resolve: "gatsby-plugin-htaccess",
            options: {
                https: true
            }
        },
    ],
}
