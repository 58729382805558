import { addAnalytics, ga_event } from "./src/handle_ga"
import '@fontsource-variable/inter';
import { bootstrapCookieFirst, onCookieFirstServiceUpdate } from "./src/tracking/cookieFirst";

export const onClientEntry = () => {
    const promises = []

    if (typeof IntersectionObserver === "undefined") {
        promises.push(import("intersection-observer"))
    }

    if (!window.fetch) {
        promises.push(import("whatwg-fetch"))
    }

    if (!Object.fromEntries) {
        promises.push(
            import("fromentries").then(fromEntries => {
                Object.fromEntries = fromEntries.default
            })
        )
    }

    if(!('scrollBehavior' in document.documentElement.style)) {
        promises.push(
            import("smoothscroll-polyfill").then(
                smooth => smooth.polyfill()
            )
        )
    }

    onCookieFirstServiceUpdate((accepted) => {
        if (accepted.includes('google_analytics')) {
            addAnalytics()
        }
    })
    promises.push(bootstrapCookieFirst())

    return Promise.all(promises)
}

const sendPageView = (location) => {
    const pagePath = location
        ? location.pathname + location.search + location.hash
        : undefined

    ga_event("page_view", {
        page_path: pagePath
    })
}

export const onRouteUpdate = ({ location }) => {
    sendPageView(location)

    if ("requestAnimationFrame" in window) {
        requestAnimationFrame(() => {
            requestAnimationFrame(sendPageView)
        })
    } else {
        // simulate 2 rAF calls
        setTimeout(sendPageView, 32)
    }
}
