// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-de-tsx": () => import("./../../../src/pages/about-us.de.tsx" /* webpackChunkName: "component---src-pages-about-us-de-tsx" */),
  "component---src-pages-about-us-en-tsx": () => import("./../../../src/pages/about-us.en.tsx" /* webpackChunkName: "component---src-pages-about-us-en-tsx" */),
  "component---src-pages-imprint-de-tsx": () => import("./../../../src/pages/imprint.de.tsx" /* webpackChunkName: "component---src-pages-imprint-de-tsx" */),
  "component---src-pages-imprint-en-tsx": () => import("./../../../src/pages/imprint.en.tsx" /* webpackChunkName: "component---src-pages-imprint-en-tsx" */),
  "component---src-pages-index-de-tsx": () => import("./../../../src/pages/index.de.tsx" /* webpackChunkName: "component---src-pages-index-de-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-privacy-de-tsx": () => import("./../../../src/pages/privacy.de.tsx" /* webpackChunkName: "component---src-pages-privacy-de-tsx" */),
  "component---src-pages-privacy-en-tsx": () => import("./../../../src/pages/privacy.en.tsx" /* webpackChunkName: "component---src-pages-privacy-en-tsx" */),
  "component---src-pages-products-culture-de-tsx": () => import("./../../../src/pages/products/culture.de.tsx" /* webpackChunkName: "component---src-pages-products-culture-de-tsx" */),
  "component---src-pages-products-culture-en-tsx": () => import("./../../../src/pages/products/culture.en.tsx" /* webpackChunkName: "component---src-pages-products-culture-en-tsx" */),
  "component---src-pages-products-sales-de-tsx": () => import("./../../../src/pages/products/sales.de.tsx" /* webpackChunkName: "component---src-pages-products-sales-de-tsx" */),
  "component---src-pages-products-sales-en-tsx": () => import("./../../../src/pages/products/sales.en.tsx" /* webpackChunkName: "component---src-pages-products-sales-en-tsx" */),
  "component---src-pages-products-training-de-tsx": () => import("./../../../src/pages/products/training.de.tsx" /* webpackChunkName: "component---src-pages-products-training-de-tsx" */),
  "component---src-pages-products-training-en-tsx": () => import("./../../../src/pages/products/training.en.tsx" /* webpackChunkName: "component---src-pages-products-training-en-tsx" */)
}

